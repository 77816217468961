import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { rem } from 'polished'

import { media } from '../theme'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Module from '../components/Module'
import { Container } from '../components/Grid'

export const query = graphql`
  query ($id: String) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      metaTitle
      description
      date
      image {
        asset {
          _ref: _id
          localFile {
            publicURL
          }
        }
      }
      _rawBody
    }
  }
`

const TitleModule = styled(Module).attrs(({ title, image }) => ({
  module: {
    _type: 'titleModule',
    variant: 'dark',
    title,
    image,
  },
}))`
  h1 {
    font-size: ${rem(28)};
    margin: ${rem(80)} 0 ${rem(12)};
    ${media('sm')`
      font-size: ${rem(38)};
      margin: ${rem(160)} 0 ${rem(32)};
    `}
  }
`

const ContainerWrapper = styled.div`
  ${media('sm')`
    padding: 0 ${rem(28)};
  `}
`
const DateLabel = styled.p`
  font-size: ${rem(14)};
  text-align: left;
  margin: ${rem(6)} 0 0;
  padding: 0 ${rem(21)};
  color: ${theme('colors.medium')};
  ${media('sm')`
    padding: 0 ${rem(64)};
  `}
  ${media('md')`
    padding: 0 ${rem(64)};
  `}
`

const IndexPage = ({ data: { post }, location: { pathname: path } }) => (
  <Layout>
    <SEO
      title={post.metaTitle || post.title}
      description={post.description}
      image={post.image ? post.image.asset.localFile.publicURL : null}
      path={path}
    />
    <TitleModule title={post.title} image={post.image} />
    <ContainerWrapper>
      <Container>
        <DateLabel>{post.date}</DateLabel>
      </Container>
    </ContainerWrapper>
    <Module
      module={{
        _type: 'textModule',
        align: 'left',
        variant: 'light',
        body: post._rawBody,
      }}
    />
  </Layout>
)

export default IndexPage
